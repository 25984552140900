import React from "react"
import styled, { useTheme } from "styled-components"
import CultureBox from "../../components/culture-box"
import IntlText from "../../components/intl-text"
import Layout from "../../components/layout"
import PageHeader from "../../components/page-header"
import PageWrapper from "../../components/page-wrapper"
import { breakpoints } from "../../theme"
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image"

const intlPath = "pages.culture.blocks"

const data = [
  {
    id: "1",
    title: `${intlPath}.remote.title`,
    body: `${intlPath}.remote.body`,
    className: "first wow slideInLeft",
    imageAlt: "Remote First",
    imageDark: "culture/dark/remote-first.png",
    image: "culture/light/remote-first.png",
  }, {
    id: "2",
    title: `${intlPath}.live.title`,
    body: `${intlPath}.live.body`,
    className: "wow slideInRight",
    imageAlt: "Live = Learn",
    imageDark: "culture/dark/live-learn.png",
    image: "culture/light/live-learn.png",
  }, {
    id: "3",
    title: `${intlPath}.time.title`,
    body: `${intlPath}.time.body`,
    className: "wow slideInLeft",
    imageAlt: "Be 100% All the Time",
    imageDark: "culture/dark/be-100.png",
    image: "culture/light/be-100.png",
  }, {
    id: "4",
    title: `${intlPath}.superheroes.title`,
    body: `${intlPath}.superheroes.body`,
    className: "dashed wow slideInRight",
    imageAlt: "Not Superheroes",
    imageDark: "culture/dark/not-superhero.png",
    image: "culture/light/not-superhero.png",
  }, {
    id: "5",
    title: `${intlPath}.vision.title`,
    body: `${intlPath}.vision.body`,
    className: "wow slideInLeft",
    imageAlt: "Our Vision Strives for Quality",
    imageDark: "culture/dark/quality.png",
    image: "culture/light/quality.png",
  }, {
    id: "6",
    title: `${intlPath}.comunicate.title`,
    body: `${intlPath}.comunicate.body`,
    className: "wow slideInRight",
    imageAlt: "Communicate and Share",
    imageDark: "culture/dark/communicate-and-share.png",
    image: "culture/light/communicate-and-share.png",
  }, 
];

const Content = styled.div`
  margin-top: 68px;

  @media (min-width: ${breakpoints.medium}) {
    & .box-container:nth-child(2n) {
      margin-top: 100px;
    }
  }
`

const Culture = () => {
  const { isDark } = useTheme();
  const {ImagesCulture} = useStaticQuery(graphql`
  query ImagesCulture {
    ImagesCulture: allFile(filter: {relativePath: { regex: "/culture/"}}){
      edges {
        node {
          id
          base
          relativePath
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED,
                formats: [AUTO,WEBP]
              )
            }
        }
      }
    }
  }
`);

  return (
    <Layout
      pageTitle="Culture"
      title="The outsourcing culture at your fingertips - Sysgarage"
      description="We have an outsourcing culture for your business. We achieve the goals by working together with a single common objective in mind. Contact Us!"
    >
      <PageWrapper>
        <PageHeader 
        title={<IntlText id="pages.culture.title" />} 
        body={<IntlText type="h2" id="pages.culture.body" />}
        />
        <Content className="row">
          {data.map((value, key) => (
            <div className="box-container col-12 col-md-6" key={key.toString()}>
              <CultureBox
                id={value.id}
                title={<IntlText id={value.title} />}
                body={<IntlText id={value.body} />}
                className={value.className}
                image={getImage(ImagesCulture.edges.filter((i) => i.node.relativePath.includes(isDark ? value.imageDark : value.image))[0].node.childImageSharp)}
                imageAlt={value.imageAlt}
              />
            </div>
          ))}
        </Content>
      </PageWrapper>
    </Layout>
  )
}

export default Culture
