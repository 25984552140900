import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { breakpoints } from "../theme"
import { body1, title1 } from "./global-styles"
import "./culture-box.scss"; 

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &:not(.first) {
    margin-top: 96px;
  }
  &.dashed {
    & .text {
      text-decoration: line-through;
    }
  }
  @media (min-width: ${breakpoints.medium}) {
    margin-top: 0;
    &:not(.first) {
      margin-top: 0;
    }
  }
`
const Title = styled.h2`
  ${title1};
  text-align: center;
  margin-top: 32px;
`
const Body = styled.p`
  ${body1};
  text-align: center;
  margin-top: 18px;
`

const CultureBox = ({ id, title, body, image, imageAlt, ...rest }) => {
  return (
    <Container {...rest}>
      <GatsbyImage
        objectFit="contain"
        className="image-culture-box w-md-50"
        image={image}
        alt={imageAlt} />
      <Title className="title">
        <span className="sg-text-gradient">#{id} </span>
        <span className="text">{title}</span>
      </Title>
      <Body>{body}</Body>
    </Container>
  )
}

export default CultureBox
